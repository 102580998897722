<script setup lang="ts">
import { onMounted, onBeforeUnmount, ref } from 'vue'
import draggable from 'vuedraggable'
import { Icon } from '@/components/common'
import type { TaskStatus } from '../../models/taskStatus'
import { toRefs } from 'vue'
import Column from './Column.vue'
import type { SortableEvent } from './types'
import type { Task } from '../../models/task'
import { useTasksTeamStore } from '../../teamStore'
import StatusHeader from '@modules/tasks/components/StatusHeader.vue'

const store = useTasksTeamStore()
const { statuses, stageTaskMap } = toRefs(store)

const handleStatusMove = (event: SortableEvent<Task>) => {
  const { newIndex, oldIndex } = event
  store.moveStatus(oldIndex, newIndex)
}

const containerRef = ref<HTMLDivElement | null>(null)

const saveScrollPosition = () => {
  if (containerRef.value) {
    localStorage.setItem(
      'taskBoardScrollPosition',
      containerRef.value.scrollLeft.toString(),
    )
  }
}

const restoreScrollPosition = () => {
  if (containerRef.value) {
    const scrollPosition = localStorage.getItem('taskBoardScrollPosition')
    if (scrollPosition) {
      containerRef.value.scrollLeft = parseInt(scrollPosition, 10)
    }
  }
}

onMounted(() => {
  restoreScrollPosition()
  containerRef.value?.addEventListener('scroll', saveScrollPosition)
})

onBeforeUnmount(() => {
  containerRef.value?.removeEventListener('scroll', saveScrollPosition)
})
</script>
<template>
  <div ref="containerRef" :class="$style.container">
    <draggable
      :class="$style.board"
      :model-value="statuses.filter((status) => status.isVisible)"
      :disabled="true"
      animation="200"
      group="stages"
      item-key="id"
      @update="handleStatusMove"
    >
      <template #item="{ element }: { element: TaskStatus }">
        <Column :stage="element" :tasks="stageTaskMap[element.id] || []" />
      </template>
      <template #footer>
        <div :class="[$style.column, $style.new]" @click="store.addStatus">
          <Icon icon="plusSmall" :size="24" />
          Create new column
        </div>
      </template>
    </draggable>

    <div :class="$style.hidden_statuses">
      <div
        v-for="status of statuses.filter((s) => !s.isVisible)"
        :key="status.id"
        :class="$style.item"
      >
        <StatusHeader
          :count="status.tasksCount as number"
          :status="status"
          :enable-add-task="false"
          :allowed-actions="['rename', 'delete', 'hide']"
        />
      </div>
    </div>
  </div>
</template>
<style module lang="scss">
.container {
  height: 100%;
  display: flex;
  flex-grow: 1;
  overflow-x: auto;
  column-gap: 8px;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 999px;
    background-color: var(--neutral-12);
  }

  .hidden_statuses {
    display: flex;
    flex-direction: column;
    row-gap: 4px;

    .item {
      width: 340px;
      border: 1px solid var(--panel-3);
      background-color: var(--panel-3);
      padding: 0 8px;
      border-radius: 12px;
    }
  }

  .board {
    display: flex;
    column-gap: 8px;
    padding-bottom: 8px;
    margin-bottom: 8px;

    .new {
      @extend .medium-3;
      display: flex;
      width: 340px;
      flex-shrink: 0;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      row-gap: 8px;
      color: var(--neutral-11);
      border: 1px dashed var(--neutral-alpha-4);
      background-color: transparent;
      transition:
        background-color 0.3s,
        color 0.3s;
      cursor: pointer;

      &:hover {
        background-color: var(--neutral-alpha-2);
        color: var(--neutral-12);
      }
    }
  }
}
</style>

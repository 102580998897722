<script lang="ts" setup>
import { ref, toRefs } from 'vue'
import { Company } from '@/models/company.ts'
import { useCrmStore } from '@modules/crm/store.ts'
import type { PipelineContact } from '@modules/crm/models/pipelineContact.ts'
import { AddTag, EmptyView, MenuItem, Popover, ResourceSelector, Tag } from '@/components/common'

const store = useCrmStore()
const companies = ref<Company[]>([])

const props = defineProps<{
  contact: PipelineContact
}>()
const { contact } = toRefs(props)

const loadCompanies = () => {
  store.api.company
    .loadCompanies({ search: '' })
    .then((response) => (companies.value = response.data as Company[]))
}

const addCompany = (name: string) => {
  store.api.company.createCompany(name).then((data) => {
    companies.value.push(data)
  })
}

const handleUpdate = (company: Company) => {
  store.bulkAssignCompany([contact.value.id], company)
}
</script>

<template>
  <Popover @open="loadCompanies" :class="$style.popper">
    <template #content>
      <ResourceSelector
        :items="companies"
        item-key="id"
        title-key="name"
        selection="single"
        :selected="contact.company"
        @update="handleUpdate"
      >
        <template #empty="{ search }">
          <MenuItem
            v-if="search.length"
            :title="`Create new company '${search}'`"
            @click="addCompany(search)"
          />
          <EmptyView v-else title="No company found" />
        </template>
      </ResourceSelector>
    </template>
    <div :class="$style.display">
      <div v-if="contact.company" :class="$style.tags">
        <Tag variant="outline" size="2">{{ contact.company?.name }}</Tag>
      </div>
      <AddTag v-else size="1">Add Company</AddTag>
    </div>
  </Popover>
</template>


<style module lang="scss">
.popper {
  width: 100%;
  height: 100%;

  > div:first-of-type {
    height: 100%;
  }

  .display {
    width: 100%;
    height: 100%;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;

    .tags {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
      width: 100%;
      height: 100%;
    }
  }
}
</style>

<script setup lang="ts">
import {
  AssigneeMenu,
  Button,
  Popover,
  ResourceSelector,
  MenuItem,
  Confirm,
  EmptyView,
} from '@/components/common'
import { useCrmStore } from '../../store'
import { useCrmPipelineStore } from '../../pipelineStore'
import { reactive, ref, toRefs } from 'vue'
import StageIndicator from '../StageIndicator.vue'
import type { Tag } from '@/models/tag'
import type { Company } from '@/models/company'
import type User from '@/models/user'
import type { PipelineStage } from '../../models/pipelineStage'
import { showToast } from '@/utils'

const props = defineProps<{
  selection: number[]
  mode: 'pipeline' | 'global'
}>()
const { selection, mode } = toRefs(props)

const state = reactive<{
  tags?: Tag[]
  company?: Company
  assignee?: User
  status?: PipelineStage
}>({})

const crmStore = useCrmStore()
const { companies, tags } = toRefs(crmStore)

const store = useCrmPipelineStore()
const { stages } = toRefs(store)

const confirmPopup = ref<InstanceType<typeof Confirm> | null>(null)

const handleTagsOpen = () => {
  tags.value.length === 0 && crmStore.loadTags()
}

const handleCompanyOpen = () => {
  companies.value.length === 0 && crmStore.loadCompanies()
}

const handleDelete = () => {
  const action =
    mode.value === 'global' ? crmStore.removeContacts : store.removeContacts
  confirmPopup.value &&
    confirmPopup.value.show({
      confirmString: mode.value === 'global' ? 'delete' : undefined,
      title: 'Delete contacts',
      message:
        mode.value === 'pipeline'
          ? `You're about to delete ${selection.value.length} contacts from this pipeline. This action is permanent and cannot be undone.`
          : `You're about to delete ${selection.value.length} contacts from your list. This action is permanent and cannot be undone. You and your team members will lose access to those contacts forever. Please, confirm your purpose by typing “delete” in the input below. `,
      actions: [
        {
          title: 'Cancel',
          theme: 'neutral',
          variant: 'ghost',
        },
        {
          title: `Delete (${selection.value.length}) contacts`,
          theme: 'danger',
          isConfirm: true,
          handler: async () => action(selection.value),
        },
      ],
    })
}

const handleStatus = (status: PipelineStage) => {
  state.status = status
  store.bulkMoveContactToStage(selection.value, status).then(() => {
    showToast({
      type: 'success',
      text: `Status updated for ${selection.value.length} contacts`,
    })
  })
}

const handleAssign = (user: User[]) => {
  state.assignee = user[0]
  const action =
    mode.value === 'global'
      ? crmStore.bulkAssignContact
      : store.bulkAssignContact
  action(selection.value, user[0]).then(() => {
    showToast({
      type: 'success',
      text: `Assignee for ${selection.value.length} contacts was changed`,
    })
  })
}

const handleCompany = (company: Company) => {
  state.company = company
  const action =
    mode.value === 'global'
      ? crmStore.bulkAssignCompany
      : store.bulkAssignCompany
  action(selection.value, company).then(() => {
    showToast({
      type: 'success',
      text: `Company for ${selection.value.length} contacts was changed`,
    })
  })
}

const handleTags = (tags: Tag[], added: Tag[], removed: Tag[]) => {
  state.tags = tags
  const action =
    mode.value === 'global'
      ? crmStore.bulkAddContactTags
      : store.bulkAddContactTags
  action(selection.value, added, removed).then(() => {
    showToast({
      type: 'success',
      text: `Tags for ${selection.value.length} contacts were changed`,
    })
  })
}
</script>
<template>
  <div :class="$style.container">
    <b :class="$style.count"> {{ selection.length }} selected </b>
    <div :class="$style.controls">
      <Button
        :class="$style.link"
        :disabled="!selection.length"
        variant="ghost"
        theme="neutral"
        size="2"
        icon="trashCan2"
        @click="handleDelete"
        >Delete</Button
      >
      <Popover @open="handleTagsOpen" :disabled="!selection.length">
        <template #content>
          <p :class="$style.changeLabel">
            Add tags to ({{ selection.length }}) contacts
          </p>
          <ResourceSelector
            :items="tags"
            :selected="state.tags"
            item-key="id"
            title-key="name"
            selection="multi"
            @update="handleTags"
          >
            <template #empty="{ search }">
              <MenuItem
                v-if="search.length"
                :title="`Create new tag '${search}'`"
                @click="crmStore.createTag(search)"
              />
              <EmptyView v-else title="No tags found" />
            </template>
          </ResourceSelector>
        </template>
        <Button
          :class="$style.link"
          :disabled="!selection.length"
          variant="ghost"
          theme="neutral"
          size="2"
          icon="hashtag"
          >Tags</Button
        >
      </Popover>
      <Popover @open="handleCompanyOpen" :disabled="!selection.length">
        <template #content>
          <p :class="$style.changeLabel">
            Change company for ({{ selection.length }}) contacts
          </p>
          <ResourceSelector
            :items="companies"
            :selected="state.company"
            item-key="id"
            title-key="name"
            selection="single"
            @update="handleCompany"
          />
        </template>
        <Button
          :class="$style.link"
          :disabled="!selection.length"
          variant="ghost"
          theme="neutral"
          size="2"
          icon="block"
          >Company</Button
        >
      </Popover>
      <Popover :disabled="!selection.length">
        <template #content>
          <p :class="$style.changeLabel">
            Assign ({{ selection.length }}) contacts to
          </p>
          <AssigneeMenu
            :assignees="state.assignee ? [state.assignee] : []"
            :single-selection="true"
            @update="handleAssign"
          />
        </template>
        <Button
          :class="$style.link"
          :disabled="!selection.length"
          variant="ghost"
          theme="neutral"
          size="2"
          icon="peopleCircle"
          >Assign</Button
        >
      </Popover>
      <Popover v-if="mode === 'pipeline'" :disabled="!selection.length">
        <template #content>
          <p :class="$style.changeLabel">
            Change status for ({{ selection.length }}) contacts
          </p>
          <ResourceSelector
            :items="stages"
            :selected="state.status"
            item-key="id"
            title-key="title"
            selection="single"
            @update="handleStatus"
          >
            <template #icon="{ item }">
              <StageIndicator
                :size="16"
                :stage="item?.order || 0"
                :total-stages="stages.length"
              />
            </template>
          </ResourceSelector>
        </template>
        <Button
          :class="$style.link"
          :disabled="!selection.length"
          variant="ghost"
          theme="neutral"
          size="2"
          icon="circleHalfFill"
          >Status</Button
        >
      </Popover>
    </div>
    <Confirm ref="confirmPopup" />
  </div>
</template>
<style module lang="scss">
.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
}
.count {
  @extend .semibold-3;
}
.controls {
  display: flex;
  flex-direction: row;
  column-gap: 16px;

  .link {
    padding: 0;
    color: var(--neutral-alpha-11);

    &:hover {
      color: var(--neutral-alpha-12);
      background-color: transparent;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: transparent;
    }
  }
}
.changeLabel {
  @extend .medium-2;
  color: var(--neutral-alpha-10);
  padding: 8px 8px 0px;
}
</style>

<script setup lang="ts">
import { Breadcrumbs, Page, type Crumb } from '@/components/common/layout'
import { useCrmStore, type ContactSearch } from './store'
import {
  Button,
  Popover,
  Popup,
  ResourceSelector,
  type DropDownItem,
} from '@/components/common'
import { useWorkspaceStore } from '@/store/workspace'
import { computed, reactive, ref, watch } from 'vue'
import BoardView from './components/BoardView/index.vue'
import ListView from './components/ListView/index.vue'
import { useRouter } from 'vue-router'
import AddContact from './components/AddContact.vue'
import DropDown from '@/components/common/DropDown.vue'
import FilterMenu from './components/menus/FilterMenu.vue'
import { useCrmPipelineStore } from './pipelineStore'
import FilterSummary from './components/FilterSummary.vue'
import { watchDebounced } from '@vueuse/core'

const router = useRouter()
const pipelineId = ref(Number(router.currentRoute.value.params.pipelineId))

const store = useCrmPipelineStore()
if (!store.pipeline || store.pipeline.id !== pipelineId.value) {
  store.$reset()
  store.loadPipeline(pipelineId.value)
  store.loadContacts({})
}

watch(
  () => router.currentRoute.value.params.pipelineId,
  () => {
    pipelineId.value = Number(router.currentRoute.value.params.pipelineId)
    if (!store.pipeline || store.pipeline.id !== pipelineId.value) {
      store.$reset()
      store.loadPipeline(pipelineId.value)
      store.loadContacts({})
    }
  },
)

watch(
  () => store.isAddContactOpen,
  (value) => {
    value && addContactRef.value?.show()
    !value && addContactRef.value?.hide()
  },
)

const addContactRef = ref<typeof Popup>()

const crmStore = useCrmStore()
const workspaceStore = useWorkspaceStore()
const { workspace } = workspaceStore

const searchState = reactive<ContactSearch>({})
watchDebounced(
  searchState,
  () => {
    store.loadContacts(searchState)
  },
  { debounce: 200 },
)

const state = reactive<{
  display: 'list' | 'board'
}>({
  display: 'board',
})

const crumbs = computed<Crumb[]>(() => [
  {
    title: `${workspace?.title} CRM`,
    url: { name: 'CRMContacts' },
  },
  {
    title: 'Pipelines',
    url: { name: 'CRMPipelines' },
  },
  {
    title: store.pipeline?.name || '',
  },
])

const menu = computed(() => {
  return [
    ...(state.display === 'board' ? [{
      id: 'column',
      icon: 'slideTallAdd',
      title: 'Column',
      handler: store.addStage,
    }] : []),
    {
      id: 'contact',
      icon: 'peopleAdd',
      title: 'Contact',
      handler: () => {
        store.isAddContactOpen = true
      },
    },
  ]
})

</script>
<template>
  <Page :full-screen="true">
    <template #topBar>
      <Breadcrumbs :crumbs="crumbs" />
      <div :class="$style.pageActions">
        <Button
          size="2"
          variant="ghost"
          theme="neutral"
          @click="state.display = state.display === 'list' ? 'board' : 'list'"
          >{{ state.display === 'list' ? 'Board' : 'List' }} View</Button
        >
        <DropDown :width="160" :menu="menu as DropDownItem[]" :close-on-select="true">
          <Button size="2" variant="outline" theme="neutral">Create</Button>
        </DropDown>
      </div>
    </template>
    <div :class="$style.topBar">
      <div :class="$style.rightSide">
        <Popover placement="bottom-start">
          <template #content>
            <ResourceSelector
              :items="crmStore.pipelines"
              item-key="id"
              title-key="name"
              @update="
                (data) =>
                  $router.replace({
                    name: 'CRMPipeline',
                    params: { pipelineId: data.id },
                  })
              "
            />
          </template>
          <Button
            :class="$style.selector"
            icon-right="chevronGrabberVertical"
            variant="ghost"
            theme="neutral"
            size="2"
            >{{ store.pipeline?.name }}</Button
          >
        </Popover>
        <span :class="$style.contacts"
          >{{ store.contacts.length }} contacts</span
        >
      </div>
      <Popover placement="bottom-end">
        <template #content>
          <FilterMenu
            mode="pipeline"
            :search="searchState"
            @update="(data) => Object.assign(searchState, data)"
          />
        </template>
        <Button variant="soft" theme="neutral" size="2" icon="filter1"
          >Filters</Button
        >
      </Popover>
    </div>
    <FilterSummary
      v-if="Object.values(searchState).filter(Boolean).length > 0"
      mode="pipeline"
      :search="searchState"
      @update="(data) => Object.assign(searchState, data)"
    />
    <BoardView v-if="state.display === 'board'" />
    <ListView v-else />
    <Popup
      ref="addContactRef"
      title="Add Contact"
      @hide="store.isAddContactOpen = false"
    >
      <AddContact :pipeline="store.pipeline" />
    </Popup>
  </Page>
</template>
<style module lang="scss">
.pageActions {
  display: flex;
  column-gap: 12px;
}
.topBar {
  margin: 32px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .rightSide {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }

  .selector {
    @extend .semibold-4;
    padding-left: 8px;
    padding-right: 8px;
  }

  .contacts {
    @extend .regular-3;
    color: var(--neutral-11);
  }
}
</style>

<script setup lang="ts">
import type { PipelineContact } from '../../models/pipelineContact'
import { useCrmStore } from '@modules/crm/store.ts'
import { toRefs } from 'vue'
import { AddTag, EmptyView, MenuItem, Popover, ResourceSelector, Tag } from '@common/index.ts'
import { Pipeline } from '@modules/crm/models/pipeline.ts'

const props = defineProps<{
  contact: PipelineContact
}>()

const store = useCrmStore()
const { pipelines } = toRefs(store)

const handleOpen = () => {
  pipelines.value.length === 0 && store.loadPipelines()
}

const handleUpdate = (data: Pipeline[]) => {
  store.changePipelines(props.contact, data);
}
</script>
<template>
  <Popover @open="handleOpen" :class="$style.popper">
    <template #content>
      <ResourceSelector
        :items="pipelines"
        :selected="contact.pipelines || []"
        item-key="id"
        title-key="name"
        selection="multi"
        @update="handleUpdate"
      >
        <template #empty="{ search }">
          <MenuItem
            v-if="search.length"
            :title="`Create new tag '${search}'`"
            @click="store.createPipeline(search)"
          />
          <EmptyView v-else title="No pipeline found" />
        </template>
      </ResourceSelector>
    </template>
    <div :class="$style.display">
      <div v-if="contact.pipelines?.length" :class="$style.tags">
        <Tag
          v-for="pipeline in (contact.pipelines || [])"
          :key="pipeline.id"
          variant="outline"
          size="2"
        >{{ pipeline.name }}</Tag
        >
      </div>
      <AddTag v-else size="1">Add Pipeline</AddTag>
    </div>
  </Popover>
</template>

<style module lang="scss">
.popper {
  width: 100%;
  height: 100%;

  > div:first-of-type {
    height: 100%;
  }

  .display {
    width: 100%;
    height: 100%;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;

    .tags {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
      width: 100%;
      height: 100%;
    }
  }
}
</style>

<script setup lang="ts" generic="T extends Record<string, any>">
import { computed, ref, toRefs } from 'vue'
import { Popover, TextField, Menu, MenuItem } from '.'
import { watchDebounced } from '@vueuse/core'

defineOptions({ inheritAttrs: false })

const props = defineProps<{
  placeholder?: string
  items: T[]
  itemKey: keyof T
  titleKey: keyof T
  subtitleKey?: keyof T
  clearOnSelect?: boolean
}>()
const { items, titleKey, subtitleKey, clearOnSelect } = toRefs(props)

const emit = defineEmits<{
  (e: 'load', search: string): void
  (e: 'select', item: T): void
}>()

const isOpen = ref(false)

const search = ref<string>('')
watchDebounced(
  search,
  () => {
    emit('load', search.value)
  },
  { debounce: 500 },
)

const displayedItems = computed<T[]>(() => {
  if (search.value.length === 0) return items.value
  return items.value.filter(
    (item: T) =>
      item[titleKey.value].toLowerCase().includes(search.value.toLowerCase()) ||
      item[subtitleKey.value]
        .toLowerCase()
        .includes(search.value.toLowerCase()),
  )
})

const handleSelect = (item: T) => {
  if (clearOnSelect.value) {
    search.value =
      item[titleKey.value as string] || item[subtitleKey.value as string]
  }
  isOpen.value = false
  emit('select', item)
}
</script>
<template>
  <div :class="[$attrs.class, $style.container]">
    <Popover
      placement="bottom-start"
      :show="isOpen && search.length > 0"
      :class="$style.fullWidth"
      @close-outside="isOpen = false"
    >
      <template #content>
        <Menu>
          <MenuItem
            v-for="item in displayedItems"
            :key="item[itemKey as string]"
            :title="item[titleKey as string] || item[subtitleKey as string]"
            :subtitle="item[subtitleKey as string]"
            @click="handleSelect(item)"
          >
            <template #icon>
              <slot name="icon" :item="item" />
            </template>
          </MenuItem>
          <slot v-if="!items.length" name="noResults" :search="search"></slot>
        </Menu>
      </template>
      <TextField
        v-bind="$attrs"
        v-model="search"
        :placeholder="placeholder"
        @focus="isOpen = true"
      />
    </Popover>
  </div>
</template>
<style module lang="scss">
.container {
  position: relative;

  :global(.popper) {
    inset: 0 !important;
  }
}
.fullWidth {
  width: 100%;
}
</style>
